/*
  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import React from 'react';
import Helmet from 'react-helmet';
import { Provider } from 'react-redux';

import { favicon, ThemeProvider, TranslationService } from '@marapp/earth-shared';

import {
  MAP_WEGLOT_API_KEY,
  PRIMARY_COLOR_DARK,
  PRIMARY_COLOR_LIGHT,
  PRIMARY_COLOR_MAIN,
} from '../config';
import auth0 from '../config/auth0';
import Main from '../pages-client/main';
import initStore from '../store';
import { APP_NAME } from '../theme';

const { store } = initStore();

TranslationService.init(MAP_WEGLOT_API_KEY);

const defaultClickedEntities = {
  location: [],
  layer: [],
};

const THEME_CONFIG = {
  colors: {
    primary: {
      light: PRIMARY_COLOR_LIGHT,
      main: PRIMARY_COLOR_MAIN,
      dark: PRIMARY_COLOR_DARK,
    },
  },
};

const isBrowser = typeof window !== 'undefined';
const isWebGLAvailable = function () {
  if (typeof document === 'undefined') {
    return false;
  }
  try {
    const canvas = document.createElement('canvas');
    return !!(
      window.WebGLRenderingContext &&
      (canvas.getContext('webgl') || canvas.getContext('experimental-webgl'))
    );
  } catch (e) {
    return false;
  }
};

const IndexPage = () => {
  const isWebGL = isWebGLAvailable();
  const [clickedEntities, setClickedEntities] = React.useState(defaultClickedEntities);

  if (!isBrowser) {
    return null;
  }

  return (
    <>
      <Helmet>
        <link rel="preconnect" href={auth0.config.domain} crossOrigin="anonymous" />
        <link rel="dns-prefetch" href={auth0.config.domain} />
        <link rel="icon" href={favicon.default} />
        <title>{APP_NAME}</title>
      </Helmet>
      <ThemeProvider themeConfig={THEME_CONFIG}>
        <Provider store={store}>
          <Main isWebGL={isWebGL} />
        </Provider>
      </ThemeProvider>
    </>
  );
};

export default IndexPage;
