import { ILayer, ILayerGroup } from '../modules/layers/model';
import { getUrlQueryParams } from './index';

export interface IUrlCoordinates {
  latitude: number;
  longitude: number;
  zoom: number;
}

export const toUrlCoordinateNumber = (value: string): number =>
  Number(parseFloat(value).toFixed(7));

export const isValidCoordinate = (value: number, limit: number): boolean =>
  (value || value === 0) && value >= -1 * limit && value <= limit;

export const isValidLatitude = (value: number): boolean => isValidCoordinate(value, 90);

export const isValidLongitude = (value: number): boolean => isValidCoordinate(value, 180);

// https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/?size=n_10_n#maxzoom
export const isValidZoom = value => value && value >= 0 && value <= 24;

export const extractBasemapFromUrl = (): string => {
  const { basemap } = getUrlQueryParams();

  return basemap as string;
};

/**
 * Extract valid viewport coordinates from the url (string)
 */
export const extractCoordinatesFromUrl = (): IUrlCoordinates => {
  const { coordinates } = getUrlQueryParams();

  const result = {
    latitude: null,
    longitude: null,
    zoom: null,
  };

  if (coordinates) {
    const [latitude, longitude, zoom] = (coordinates as string).split(',');

    result.latitude = toUrlCoordinateNumber(latitude);
    result.longitude = toUrlCoordinateNumber(longitude);
    result.zoom = parseInt(zoom, 10);
  }

  return result;
};

/**
 * Based on the viewport stored in redux, under map.viewport
 * extract only the valid properties that need to be stored in the URL
 */
export const reduxViewportToUrl = (value: IUrlCoordinates) => {
  return [
    toUrlCoordinateNumber(String(value.latitude)),
    toUrlCoordinateNumber(String(value.longitude)),
    parseInt(String(value.zoom), 10),
  ];
};

/**
 * Url params (from the URL component) are returned as an array
 * Create a valid viewport, based on the coordinates provided by the URL component
 */
export const urlToReduxViewport = (initialView: IUrlCoordinates) => {
  return (value: number[]): IUrlCoordinates => {
    const [
      latitude = initialView.latitude,
      longitude = initialView.longitude,
      zoom = initialView.zoom,
    ] = value;

    return { latitude, longitude, zoom };
  };
};

export const isValidUrlCoordinateGroup = (value: IUrlCoordinates) => {
  return (
    isValidLatitude(value.latitude) && isValidLongitude(value.longitude) && isValidZoom(value.zoom)
  );
};

export const reduxLayersToUrl = (layersFromUrl: string[]) => {
  const layers = layersFromUrl.map(l => {
    const [name, opacity = '100'] = l.split('_');
    return {
      name,
      opacity,
    };
  });

  return {
    active: layers.map(l => l.name),
    settings: layers.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.name]: {
          opacity: parseInt(curr.opacity, 10) / 100,
        },
      }),
      {}
    ),
  };
};

export const urlToReduxLayers = reduxValue => {
  return reduxValue.active.map(l => {
    const { opacity = 1 } = reduxValue.settings[l] || {};
    return `${l}${opacity ? '_' + Math.floor(opacity * 100) : '_100'}`;
  });
};

// search layerGroup for a layer with a matching id, including references
export const layerIdFromGroup = (group: ILayerGroup[], id: string): ILayer => {
  let layer;
  group.forEach(g => {
    g.layers.forEach(l => {
      if (l.id === id) {
        layer = l;
      }
      if (l.references) {
        l.references.forEach(r => {
          if (r.id === id) {
            layer = r;
          }
        });
      }
    });
  });
  return layer;
};
